.setting-card{
   margin : 2rem auto ;
    padding-left: 2rem;
    padding-right: 2rem;    
    border-radius: 1rem!important;
    border: none!important;
    height: calc(100vh - 16rem ) !important;
}
.setting-card .card-header{
    height: 6rem;
    background-color: transparent;
}
.setting-card .card-header h4 {
    color: #3453DF !important;
}
.setting-card> .card-body{
    padding-top: 2rem;
    padding-left: 2.25rem;
    padding-right: 2.25rem;
}
input,select{
    border-color:#3453DF!important ;
    box-shadow: none!important;
    color: #112361!important;
}
.save-btn,.save-btn:hover,.save-btn:active{
    box-shadow: none!important;
    margin-top: 2rem;
    margin-bottom: 2.8rem;
}
label{
    color:rgba(17, 35, 97, 0.24)!important;
    margin-bottom: 1rem !important;
    font-size: 1.25rem;
}