.card-text {
  margin-bottom: 0;
}
#whiteSpace {
  white-space: normal;
}

#tp-btn::after {
  content: "";
}
.progress .progress-bar {
  animation-name: animateBar;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.6s;
}
@keyframes animateBar {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
ol.go2441762052 {
  text-align: center;
}

#order2 {
  order: 2;
}

/* Chart */

.go2478912419 {
  text-align: center;
  display: block;
  margin-bottom: 2rem;
}
.summernote > :first-child {
  min-height: 250px;
}
.picker-input__text,
.range-picker-input {
  width: 100% !important;
}

.timepicker-container,
.MuiFormControl-root.MuiTextField-root {
  flex: 1 1 auto;
}

.timepicker-container input {
  background: #fff;
  border: 1px solid #f0f1f5;
  color: #b1b1b1;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  height: 41px;
  width: 100%;
  box-sizing: border-box;
}

.MuiFormControl-root.MuiTextField-root {
  display: block;
}
.MuiInputBase-root.MuiInput-root.MuiInput-underline.MuiInputBase-formControl.MuiInput-formControl {
  width: 100%;
  height: 100%;
}

/* Picker */
.slick-slider .items {
  padding: 0.5rem;
}
.slick-next:before,
.slick-prev:before {
  line-height: 1;
  opacity: 0.75;
  color: #6cc51d;
  position: absolute;
  top: 50%;
  width: 20px;
  height: 20px;
  margin-top: -14px;
  right: 30px;
  font-size: 32px;
}
.slick-next::after,
.slick-prev::after {
  line-height: 1;
  opacity: 0.75;
  color: #6cc51d;
  position: absolute;
  top: 50%;
  width: 20px;
  height: 20px;
  margin-top: -14px;
  right: 30px;
  font-size: 32px;
  z-index: 99;
}
.items {
  padding-right: 25px;
}

.react-calendar {
  border: none;
}
.icon-false:after {
  content: none !important;
}

.swal-footer {
  text-align: center !important;
}

rect#chart_ChartBorder {
  fill: transparent;
}

/* Height 80 */
.h-100m {
  min-height: 93vh;
}
.h-80 {
  min-height: 80vh;
}
.h-75 {
  min-height: 75vh;
}
.h-70 {
  min-height: 70vh;
}

/* pagination */
.pagination {
  margin-bottom: 0 !important;
}
@import url("https://fonts.googleapis.com/css?family=Lato");
.rc-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 80;
}
.rc-backdrop.invert {
  background: rgba(1, 1, 1, 0.7);
}
.range-picker-input {
  display: inline-flex;
  border: 1px solid #e4e4e4;
  width: 300px;
}
.range-picker-input * {
  box-sizing: border-box;
}
.range-picker-input__icon {
  display: inline-flex;
  align-items: center;
}
.range-picker-input__start,
.range-picker-input__end {
  display: inline-flex;
  flex: 1;
}
.range-picker-input__start .picker-input.range input,
.range-picker-input__end .picker-input.range input {
  width: 100%;
  border: none;
}
.picker-input {
  display: inline-block;
  position: relative;
}
.picker-input__icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
  display: inline-flex;
  align-items: center;
}
.picker-input__text {
  padding: 10px;
  border: 1px solid #e4e4e4;
  outline: none;
  font-size: 16.8px;
}
.picker-input__text:disabled {
  background: #e4e4e4;
}
.picker-input__clear {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  cursor: pointer;
}
.picker {
  display: inline-block;
}
.picker__container {
  position: absolute;
  z-index: 100;
}
.picker__container.portal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.picker__container__include-time {
  border: 1px solid #e4e4e4;
}
.picker__container__include-time .calendar__item,
.picker__container__include-time .time__container {
  border: none;
}
.picker__container__tab {
  margin: 10px 0;
}
.picker__container__tab button {
  padding: 5px 10px;
  outline: none;
  display: inline-flex;
  align-items: center;
  background: none;
  border: none;
  border-bottom: 2px solid #e4e4e4;
}
.picker__container__tab button.active {
  color: #49599a;
  border-bottom: 2px solid #49599a;
}
.picker__container__tab button:first-child {
  border-right: none;
}
.picker__container__tab button svg {
  margin-right: 5px;
}
.time__container {
  display: inline-flex;
  align-items: center;
  border: 1px solid #e4e4e4;
  padding: 15px;
  background: white;
  font-family: "Lato";
  font-size: 12px;
}
.time__container__div {
  margin: 0 10px;
}
.time__container__type {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.time-input {
  display: inline-block;
  width: 40px;
  overflow: hidden;
}
.time-input__up,
.time-input__down {
  border: 1px solid #e4e4e4;
}
.time-input__up button,
.time-input__down button {
  outline: none;
  width: 100%;
  cursor: pointer;
  border: none;
}
.time-input__text {
  width: 100%;
  border-left: 1px solid #e4e4e4;
  border-right: 1px solid #e4e4e4;
  box-sizing: border-box;
}
.time-input__text input {
  width: 100%;
  box-sizing: border-box;
  border: none;
  font-size: 15px;
  padding: 5px;
  text-align: center;
  outline: none;
}
.calendar {
  display: inline-block;
  background: white;
  font-size: 12px;
}
.calendar *,
.calendar *:before,
.calendar *:after {
  box-sizing: border-box;
}
.calendar__container {
  width: 270px;
  font-family: "Roboto", sans-serif;
  display: none;
}
.calendar__list {
  display: table;
}
.calendar__item {
  display: table-cell;
  border: 1px solid #ececec;
}
.calendar__item:not(:first-child) {
  border-left: none !important;
}
.calendar--show {
  display: inline-block;
}
.calendar__head {
  position: relative;
  background: #7986cb;
  padding: 10px 6px;
}
.calendar__head--title {
  font-size: 1.3em;
  color: white;
  text-align: center;
  margin: 4px;
}
.calendar__head--button {
  outline: none;
  border: none;
  cursor: pointer;
  background: none;
  font-size: 20px;
}
.calendar__head--button svg {
  fill: white;
}
.calendar__head--prev,
.calendar__head--next {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}
.calendar__head--prev {
  left: 0;
}
.calendar__head--next {
  right: 0;
}
.calendar__panel--show {
  display: block !important;
}
.calendar__panel--today {
  background: #aab6fe;
  padding: 5px;
  display: none;
}
.calendar__panel--today h2 {
  margin: 0;
  cursor: pointer;
  font-size: 12px;
  text-align: center;
}
.calendar__body--table {
  width: 100%;
  table-layout: fixed;
  text-align: center;
  border-spacing: none;
  border-collapse: collapse;
}
.calendar__body--table th {
  height: 30px;
  vertical-align: middle;
  color: #212121;
}
.calendar__day {
  vertical-align: top;
  padding-top: 5px;
  height: 40px;
  cursor: pointer;
}
/* .calendar__day self:hover:not(&--disabled) {
   background: #aab6fe;
   cursor: pointer;
} */
.calendar__day--0 {
  color: red;
}
.calendar__day--6 {
  color: blue;
}
.calendar__day--today {
  background: #fff9c4;
}
.calendar__day--disabled {
  color: #ddd;
  cursor: initial;
}
.calendar__day--start,
.calendar__day--end,
.calendar__day--selected {
  background: #7986cb;
  color: #fff;
}
.calendar__day--start:hover,
.calendar__day--end:hover,
.calendar__day--selected:hover {
  background: #7986cb;
}
.calendar__day--range {
  background: #dce1ff;
}
.calendar__day--text {
  display: block;
  font-size: 10px;
}
.calendar__year,
.calendar__month {
  height: 55px;
  vertical-align: middle;
}
.calendar__year:hover,
.calendar__month:hover {
  background: #aab6fe;
  cursor: pointer;
}

.form-control::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: red;
  opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: red;
}

.form-control::-ms-input-placeholder {
  /* Microsoft Edge */
  color: red;
}
button,
input {
  height: 3.5rem !important;
  font-size: 1.125rem !important;
  border-radius: 8px !important;
}
input {
  padding-inline: 1.3rem !important;
}
@media (min-width: 1299px) {
  .content-body {
    margin-left: 18.563rem !important;
  }
}
.content-body .container-fluid {
  /* padding: 1.5rem !important; */
  padding: 0rem !important;
  background-color: #eff0f8 !important;
  height: calc(100% - 0.5rem);
  overflow: auto;
  margin-top: 0.5rem;
}

@media only screen and (min-width: 768px) {
  [data-sidebar-style="mini"] .content-body {
    margin-left: 7.563rem !important;
  }
}
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .content-body {
  margin-left: 7.563rem !important;
}
@media only screen and (max-width: 767px) {
  .content-body {
    margin-left: 0 !important;
  }
  .prescriptions-dash {
    height: 12rem !important;
  }
}
@media only screen and (max-width: 568px) {
  .prescriptions-dash {
    height: 14.5rem !important;
  }
}

.scroll {
  overflow: auto;
  max-height: calc(100vh - 8rem - 24px);
}
.nav-container {
  height: 3.5rem !important;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  height: 100% !important;
  border-radius: 8px !important;
  border-bottom: 1px solid #dee2e6 !important;
}
.prescriptions .nav-link.active {
  width: 11.31rem !important;
}
.nav-tabs .nav-link.active {
  background-color: #3453df !important;
  color: #fff !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  width: 9.31rem;
  font-family: "Gilroy-Meduim", sans-serif;
  font-size: 1.25rem;
  animation-name: drop;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.5s;
}

.nav-tabs .nav-item {
  background: white !important;
  border-radius: 0.5rem;
  height: 3.5rem !important;
}
.nav-tabs {
  border-bottom: 0 !important;
  border-radius: 0.5rem;
  height: 3.5rem !important;
  width: fit-content !important;
}
.nav-tabs .nav-link {
  height: 100%;
  gap: 0.5rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 11rem;
  color: #112361 !important;
  font-family: "Gilroy-Meduim" sans-serif;
  font-size: 1.25rem;
}
.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border: none;
}

.btn {
  color: #fff !important;
  height: 3.5rem !important;
  width: 15.375rem;
  border-radius: 0.5rem !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  gap: 1rem !important;
}
.btn-title {
  font-family: "Gilroy-SemiBold", sans-serif !important;
  font-size: 1.125rem;
}
.login-button-shadow {
  box-shadow: 0 4px 4px 0 #00000025;
}
.google-user {
  background-color: #3453df08 !important ;
}
.btn-google-user {
  color: #112361 !important;
}
.add-user {
  background-color: #3453df !important ;
}
.user-signale {
  background-color: #ed1607 !important ;
}
.user-signale:focus {
  box-shadow: none !important;
}
.modal-content {
  border-radius: 24px !important;
  background: #eff0f8 !important;
  border: none !important;
  padding: 2rem;
}
.modal-header .close {
  margin: 0 !important;
  padding: 3rem 2rem !important;
  font-size: 1.75rem !important;
  height: 4rem !important;
  font-weight: 500 !important;
  color: #112361 !important;
}
.modal-header {
  height: 5rem !important;
  border-bottom: 0px !important;
  padding-bottom: 0 !important;
}
.modal-title {
  font-family: "Gilroy-SemiBold", sans-serif;
  font-size: 1.5rem;
  color: #112361;
}

.description {
  font-size: 1.125rem !important;
  font-family: "Gilroy-Medium", sans-serif;
  color: #112361;
}
.send-btn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 4rem;
}

.client-card {
  gap: 1.5rem;
}
.contact-bx {
  width: 23.5rem !important;
  height: 13.75rem !important ;
  border-radius: 1rem !important;
  margin-bottom: 0 !important;
  border: transparent !important;
  box-shadow: none !important;
}
.contact-bx-body {
  width: 23.5rem !important;
  height: 13.75rem !important ;
  border-radius: 1rem !important;
}

.contact-bx:hover {
  border-color: transparent !important;
  box-shadow: none !important;
}

.user-info {
  gap: 1.25rem;
  padding-left: 2rem;
  padding-top: 2rem;
  margin-bottom: 1.5rem;
}
.user-img {
  width: 5rem;
  height: 5rem;
}
.user-address {
  gap: 0.5rem;
}
.user-name {
  font-family: "Gilroy-SemiBold", sans-serif;
  font-size: 1.5rem;
  color: #0a113d;
}
.address {
  font-family: "Gilroy-Regular", sans-serif;
  font-size: 1.125rem;
}

.user-data {
  padding-left: 2rem;
  padding-right: 2rem;
  margin-bottom: 0.75rem;
}
.user-id {
  font-family: "Gilroy-Medium", sans-serif;
  font-size: 1.125rem;
  color: #0a113d;
}
.user-opacity {
  opacity: 48% 
}
.row-container {
  height: calc(100% - 112px) !important;
}
.about-container {
  height: 12.5rem;
  width: 49%;
}
.about-card {
  border-radius: 22px !important;
  border: transparent !important;
}
.about-card .card-body {
  padding: 1.875rem !important;
}
.about-card .card-body h4 {
  font-family: "Gilroy-Bold", sans-serif;
  color: #0a113d;
  font-size: 24px;
  font-weight: 600 !important;
  margin-bottom: 0.75rem;
}
.about-card .card-body p {
  font-family: "Gilroy-Meduim", sans-serif;
  color: #0a113d;
  font-size: 18px;
  opacity: 65%;
}
.dashboard-container {
  height: calc(100vh - 11.5rem);
  gap: 2rem;
}
.left-side {
  gap: 1.5rem;
  width: 65%;
}
.right-side {
  width: 35 !important;
}
.left-side-top {
  height: 10rem;
  gap: 1rem;
}
.left-side-bottom {
  height: calc(100% - 10rem);
}
.prescriptions-bg {
  background-color: #2bc155 !important;
  /* opacity: 80% !important ; */
}
.client-bg {
  background-color: #3453df !important;
  /* opacity: 80% !important; */
}
.pending-users-bg {
  /* opacity: 80% !important; */
  background-color: #ffa500 !important;
}
.dashboard-cards-opacity{
  opacity: 80% !important;

}
.widget-stat {
  border-radius: 1rem !important;
  border: transparent !important;
}
.prescription-titles {
  font-size: 1.25rem;
  font-family: "Gilroy-Meduim", sans-serif;
  color: #fff;
}
.prescriptions-section {
  gap: 1.5rem;
}
.widget-stat .card-body {
  padding: 2rem !important;
}
.icons {
  font-size: 1.75rem !important;
}

.chart-title {
  font-size: 20px;
  font-family: "Gilroy-SemiBold", sans-serif;
  color: #0a113d;
}
.apexcharts-legend.apx-legend-position-right,
.apexcharts-legend.apx-legend-position-left {
  justify-content: space-evenly !important;
  align-items: flex-start;
}
.apexcharts-legend-series {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 0.5rem !important;
}
.apexcharts-legend-marker {
  height: 1.25rem !important;
  width: 1.25rem !important;
}
.apexcharts-legend-text {
  font-size: 1.125rem !important;
  font-family: "Gilroy-SemiBold", sans-serif !important;
  color: #0a113d !important;
}
.dashboard-list-header {
  border-radius: 1rem 1rem 0px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #11236116;
  margin-bottom: 1rem;
}
.dashboard-list-titles {
  color: #112361 !important;
  font-size: 1.25rem !important;
  font-family: "Gilroy-SemiBold", sans-serif !important;
  font-weight: 600 !important;
}
.dashboard-list-length {
  color: #112361 !important;
  font-size: 1.25rem !important;
  font-family: "Gilroy-Bold", sans-serif !important;
  font-weight: 700 !important;
}
.client {
  color: #2c47db !important;
  font-size: 1.25rem !important;
  font-family: "Gilroy-SemiBold", sans-serif !important;
}
.client-date {
  color: #112361 !important;
  font-size: 1.25rem !important;
  font-family: "Gilroy-Regular", sans-serif !important;
}
.pending-client {
  color: #fff !important;
  font-size: 1rem !important;
  font-family: "Gilroy-Medium", sans-serif !important;
  font-weight: 500 !important;
}
.btn-pending {
  height: 2.5rem !important;
  background-color: #ffa500;
  border-radius: 1.5rem;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes drop {
  0% {
    transform: translateX(-20%);
  }
  100% {
    transform: translateX(5);
  }
}
.add-user:hover {
  background: transparent !important;
  border: 2px solid #3453df;
  box-shadow: none !important;
  color: #3453df !important;
}
.user-signale:hover {
  background: transparent !important;
  border: 2px solid #ed1607;
  box-shadow: none !important;
  color: #ed1607 !important;
}
.add-user:hover .btn-title {
  color: #3453df !important;
}
.add-user:hover path {
  fill: #3453df !important;
}
.user-signale:hover .btn-title {
  color: #ed1607 !important;
}
.user-signale:hover path {
  fill: #ed1607 !important;
}
.add-user:focus {
  background: #112361 !important;
  box-shadow: none !important;
  border: none !important;
  color: #fff !important;
}
.add-user:focus .btn-title {
  color: #fff !important;
}
.add-user:focus path {
  fill: #fff !important;
}

.settings-container {
  height: calc(100vh - 11rem) !important;
  border-radius: 1rem !important;
  border: none !important;
}
.titles {
  font-family: "Gilroy-SemiBold", sans-serif !important;
  color: #3453df;
}
.settings-title {
  font-size: 1.5rem !important;
}
.settings-info-title {
  font-size: 1.25rem !important;
}
.settings-form-titles {
  font-family: "Gilroy-Meduim", sans-serif !important;
  color: #11236140 !important;
  font-size: 1.25rem !important;
}
.settings-form-values {
  font-family: "Gilroy-Meduim", sans-serif !important;
  color: #112361 !important;
  font-size: 1.125rem !important;
  border: 1px solid #3453df !important;
  background-color: #3453df04 !important;
  height: 3.5rem !important;
  border-radius: 0.5rem !important;
}
.settings-form-values:focus {
  box-shadow: none !important;
}
.no-client-card {
  height: 27.5rem !important;
  border-radius: 1rem !important;
  border: none !important;
}

.no-client-title {
  color: #112361 !important;
  font-size: 1.25rem !important;
  font-family: "Gilroy-SemiBold", sans-serif !important;
  font-weight: 600 !important;
}

table tbody {
  display: block;
  max-height: calc(100vh - 26.5rem);
  overflow: auto;
}

table thead,
table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.table thead th {
  font-size: 1.5rem !important;
  font-family: "Gilroy-SemiBold", sans-serif !important;
  color: #112361 !important;
  padding: 2rem !important;
}
.details .table thead th {
  font-size: 1.5rem !important;
  font-family: "Gilroy-SemiBold", sans-serif !important;
  color: #11236140 !important;
  padding: 2rem !important;
}

.table td,
.table th {
  padding: 2rem !important;
  border-top: 1px solid #11236116 !important;
}
.table tbody tr td {
  font-size: 1.25rem !important;
  font-family: "Gilroy-Regular", sans-serif;
  color: #112361;
}
.table-patient {
  font-family: "Gilroy-SemiBold", sans-serif !important;
  color: #2c47db !important;
}
.perscriprions-card {
  border: 0 !important;
}
.prescription-href span {
  text-wrap: balance;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  width: 1.25rem;
  height: 1.25rem;
}
input[type="time"]::-webkit-calendar-picker-indicator {
  width: 1.5rem;
  height: 1.5rem;
}
.login-container {
  padding: 2rem 3rem !important;
  height: 100vh;
  gap: 4.5rem;
  background-color: #fff;
}
.login-img-section {
  background: #11236108;
  border-radius: 1.5rem;
}
.login-title {
  font-family: "Gilroy-SemiBold", sans-serif !important;
  font-size: 3rem !important;
  color: #3453df !important;
  text-shadow: 0 4px 4px #00000025;
}
.login-description {
  font-family: "Gilroy-Meduim", sans-serif !important;
  font-size: 1.25rem !important;
  color: #112361 !important;
  text-shadow: 0 4px 4px #00000025;
  width: 50px !important;
  text-wrap: wrap !important;
}
.login-from-titles {
  font-family: "Gilroy-Meduim", sans-serif !important;
  font-size: 1.25rem !important;
  color: #11236140 !important;
  text-shadow: 0 4px 4px #00000025;
}
.forgot-password {
  font-family: "Gilroy-Meduim", sans-serif !important;
  font-size: 1rem !important;
  color: #3453df !important;
  text-shadow: 0 4px 4px #00000025;
}
.login-divider {
  border-bottom: 1px solid #11236124;
}
.signup-title {
  font-family: "Gilroy-Meduim", sans-serif !important;
  font-size: 1.125rem !important;
  color: #112361 !important;
  text-shadow: 0 4px 4px #00000025;
}
.signup-link {
  color: #3453df !important ;
}
.rights {
  font-family: "Gilroy-Meduim", sans-serif !important;
  font-size: 1rem !important;
  color: #11236132 !important;
}
.breadcrumb-item + .breadcrumb-item::before {
  content: ">" !important;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
  color: #0a113d !important;
}
.patient-info-card {
  /* height: 17rem !important; */
  height: auto !important;
}
.patient-user-name {
  font-family: "Gilroy-SemiBold", sans-serif !important;
  font-size: 1.75rem !important;
  color: #3453df !important;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.patient-ville {
  font-family: "Gilroy-Meduim", sans-serif !important;
  font-size: 1.125rem !important;
  color: #11236148 !important;
}
.patient-info {
  font-family: "Gilroy-Meduim", sans-serif !important;
  font-size: 1.5rem !important;
  color: #112361 !important;
  display: flex;
  flex-wrap: wrap;
  width: auto;
  max-width: 14rem;
}
.patient-info-title {
  font-size: 1rem !important;
}
.info:hover:before {
  position: absolute;
  height: 0.5rem !important;
  width: 100% !important;
  background: #3453df;
  content: "";
  bottom: 0.5rem !important;
  bottom: 0rem !important;
  left: 0rem !important;
  border-radius: 0 0 1.25rem 1.25rem !important;
  z-index: 99;
  top: 4.5rem !important;
}
.info:hover {
  background-color: #3453df04;
}
.info:hover .patient-info {
  color: #3453df !important;
}
.info:hover .patient-ville {
  color: #3453df48 !important;
}
.swal2-icon.swal2-warning {
  border-color: #ed1607 !important;
  color: #ed1607 !important;
}
.swal2-styled.swal2-confirm,
.swal2-styled.swal2-cancel {
  width: 40% !important;
  box-shadow: none !important;
}
.swal2-styled {
  font-family: "Gilroy-SemiBold", sans-serif !important;
  font-size: 1.125rem;
}
.swal2-html-container {
  font-family: "Gilroy-SemiBild", sans-serif !important;
  font-size: 1.25rem !important;
  color: #11236162 !important;
}
.unselectable {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 20%;
}
.modal-prescription .modal-content {
  width: 60rem;
}
.modal-footer {
  border: 0 !important;
}
