/* .dlabnav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
} */
.dlabnav .metismenu {
  align-items: center;
  padding-top: 0.5rem !important;
}
.dlabnav .metismenu > li > a {
  height: 5rem;
  width: 15.5rem;
  padding-left: 2rem !important;
  display: flex !important;
  gap: 1rem;
  align-items: center;
  justify-content: start;
}
.dlabnav .metismenu > li > a span {
  color: #112361;
  font-size: 1.25rem !important;
}
.dlabnav .metismenu > li > a svg path {
  fill: #112361 !important;
}

.dlabnav .metismenu > li:hover:before {
  position: absolute;
  height: 100%;
  width: 0.5rem;
  background: #3453df;
  content: "";
  left: 0px !important;
  top: 0;
  border-radius: 1.25rem 0 0 1.25rem !important;
}
.dlabnav .metismenu > li > a:before {
  position: absolute;
  height: 100%;
  width: 0;
  background: #3453df;
  content: "";
  left: -10rem !important;
  top: 0;
}

.dlabnav .metismenu > li:hover > a span {
  color: #3453df;
}
.dlabnav .metismenu > li:hover > a svg path {
  fill: #3453df !important;
}
.dlabnav .metismenu > li.mm-active > a {
  background: #3453df !important;
  border-radius: 8px;
  animation-name: drop;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.5s;
}
.dlabnav .metismenu > li.mm-active > a {
  background-color: #000;
}
.dlabnav .metismenu > li.mm-active > a span {
  color: #fff;
}
.dlabnav .metismenu > li.mm-active > a svg path {
  fill: #fff !important;
}
/* .menu-toggle .dlabnav {
  width: 6rem !important;
} */

.menu-toggle .dlabnav .metismenu li a svg path {
  fill: #112361;
}
.menu-toggle .dlabnav .metismenu li.mm-active a svg path {
  fill: #fff !important;
}
.menu-toggle .dlabnav .metismenu {
  align-items: center;
}
.menu-toggle .dlabnav .metismenu > li > a {
  background-color: #fff;
  width: 5rem !important;
  height: 5rem !important;
  border-radius: 8px !important;
  padding: 27px !important;
  display: flex !important;
  align-items: center !important;
}
.menu-toggle .dlabnav .metismenu > li:hover > a svg path {
  fill: #3453df !important;
}
.menu-toggle .dlabnav .metismenu > li:hover > a {
  background: #fff !important;
}
.menu-toggle .dlabnav .metismenu > li:hover:before {
  width: 0 !important;
}
.menu-toggle .dlabnav .metismenu > li:hover > a:after {
  position: absolute;
  height: 0.5rem !important;
  width: 5rem !important;
  background: #3453df;
  content: "";
  bottom: 0.5rem !important;
  bottom: 0rem !important;
  left: 0px !important;
  border-radius: 0 0 1.25rem 1.25rem !important;
  z-index: 99;
}

.dlabnav-scroll {
  overflow: scroll;
  height: calc(100% - 17.75rem) !important;
}

[data-sidebar-position="fixed"][data-layout="vertical"] .dlabnav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.dlabnav .metismenu > li.mm-active > a span {
  font-family: "Gilroy-Meduim", sans-serif !important;
  font-size: 1.25rem !important;
}
.dlabnav .metismenu > li > a span {
  font-family: "Gilroy-Meduim", sans-serif !important;
}
[data-sidebar-style="full"][data-layout="vertical"]
  .dlabnav
  .metismenu
  > li.mm-active
  > a {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
}
.dlabnav .metismenu {
  gap: 0.25rem !important;
}

[data-sidebar-position="fixed"][data-layout="vertical"] .dlabnav {
  width: 18.5rem !important;
}
[data-sidebar-position="fixed"][data-layout="vertical"] .nav-header {
  width: 18.5rem !important;
}
.header {
  padding-left: 18.563rem !important;
  height: 8rem !important;
}

[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .nav-header {
  width: 8rem !important;
}
[data-sidebar-position="fixed"][data-layout="vertical"] .menu-toggle .dlabnav {
  width: 8rem !important;
}
[data-sidebar-style="full"][data-layout="vertical"] .menu-toggle .header {
  padding-left: 8.563rem !important;
}
[data-sidebar-style="full"][data-layout="vertical"]
  .menu-toggle
  .nav-header
  .brand-logo {
  padding-left: 0 !important;
}
[data-sidebar-style="full"][data-layout="vertical"]
  .menu-toggle
  .dlabnav
  .metismenu
  > li {
  padding: 0 !important;
  height: 5rem !important;
  width: 5rem !important;
}
.video-ads {
  border-radius: 0.5rem !important;
}
.carousel-item .video-ads {
  object-fit: cover !important;
}

.banners {
  width: fit-content;
  opacity: 1;
  margin: 0.75rem .5rem 5vh .5rem !important;
}
.video-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.clickable-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 1; 
}
@media (max-width: 1299px) {
  [data-header-position="fixed"][data-sidebar-position="fixed"] .dlabnav {
    width: 8rem !important;
  }
  [data-sidebar-style="mini"] .nav-header .brand-logo {
    width: 8rem !important;
  }
  [data-header-position="fixed"] .header {
    padding-left: 5.563rem !important;
  }
  [data-sidebar-position="fixed"][data-layout="vertical"] .nav-header {
    width: 10.5rem !important;
  }
  [data-sidebar-style="mini"][data-layout="vertical"]
    .dlabnav
    .metismenu
    > li:hover
    > a {
    width: 5rem !important;
    height: 5rem !important;
    border-radius: 0.5rem !important;
    background-color: #fff !important;
    color: #3453df !important;
  }
  [data-sidebar-style="mini"][data-layout="vertical"]
    .dlabnav
    .metismenu
    > li.mm-active
    > a {
    width: 5rem !important;
    height: 5rem !important;
    border-radius: 0.5rem !important;
    padding: 0 !important;
    background-color: #3453df !important;
    color: #fff !important;
  }
  [data-sidebar-style="mini"] .dlabnav .metismenu > li.mm-active {
    display: flex !important;
    align-items: center;
  }
  [data-sidebar-style="mini"] .dlabnav .metismenu li a {
    padding: 0 !important;
  }
  .dlabnav .metismenu > li > a {
    padding-left: 1.75rem !important;
  }
  .dlabnav .metismenu {
    display: block !important;
    display: flex !important;
    align-items: center;
    gap: 0.25rem;
  }
  [data-sidebar-style="mini"] .dlabnav .metismenu li a {
    display: flex !important;
    align-items: center;
    justify-content: center;
    width: 80px !important;
    height: 80px !important;
  }
  .dlabnav .metismenu > li:hover::before {
    position: absolute;
    height: 0.5rem !important;
    width: 5rem !important;
    background: #3453df;
    content: "";
    bottom: 0.5rem !important;
    bottom: 0rem !important;
    left: 1rem !important;
    border-radius: 0 0 1.25rem 1.25rem !important;
    z-index: 99;
    top: 5rem !important;
  }
  .dlabnav .metismenu > li.mm-active:hover::before {
    display: none !important;
  }
  .ps__thumb-y {
    display: none !important;
  }
  .banners {
    display: none;
  }
}

@media only screen and (max-width: 1199px) {
  [data-header-position="fixed"] .content-body {
    padding-top: 8rem !important;
  }
  [data-sidebar-position="fixed"][data-layout="vertical"] .nav-header {
    height: 8rem !important;
  }

  .dlabnav {
    padding-top: 7.5rem !important;
  }
}
@media only screen and (max-width: 767px) {
  [data-sidebar-position="fixed"][data-layout="vertical"] .nav-header {
    width: 8rem !important;
  }
  .menu-toggle .dlabnav .metismenu > li > a {
    display: block !important;
  }
  .dlabnav .metismenu > li > a span {
    display: none !important;
  }
}
@keyframes drop {
  0% {
    transform: translateX(-20%);
  }
  100% {
    transform: translateX(5);
  }
}
